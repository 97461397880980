.firstSlide{
    visibility: block;
    height: 40vh;
    width: 200vh;
}

.secondSlide{
    visibility: block;
    height: 40vh;
    width: 200vh;
}

.thirdSlide{
    visibility: block;
    height: 40vh;
    width: 200vh;
}

.carousel{
    background-color:white;
}